<template>
  <div class="problemListOperate">
    <div class="content">
      <Breadcrumb :auto-current="false"></Breadcrumb>
      <div class="box">
        <div class="content-left">
            <router-view></router-view> 
        </div>
        <div class="content-right">
          <Advertising></Advertising>
          <HotRecommend class="margin"></HotRecommend>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Advertising from "@/components/Advertising/Advertising.vue";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  components: {
    Advertising,
    HotRecommend,
  },
  data() {
    return {
      
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.problemListOperate{
  
background: #FAFAFA;  
}
.box {
  display: flex;
  justify-content: space-between;
  .content-left {
    width: 70%;
    font-size: 14px;
    color: #2f2f2f;

  }
  .content-right {
    width: 27%;
    // .margin {
    //   margin-top: 40px;
    // }
  }
}
</style>
